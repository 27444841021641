<template>

  <div class="trips-new">
    <main-nav />
    <main-titlebar />

 
     <Form @submit="createContact" :validation-schema="schema">
      <div  class="trip-view-wrapper" style="display:block;">
        <!-- <Field name="origin_address"  v-model="origin_address"  type="text"/> -->


      <!-- Name -->
      <div class="form-group">
          <Field name="name" id="name" class="form-input" v-model="name"  type="text" placeholder="Naam contact/klant"/>
      </div>
      <div class="form-group">
        <ErrorMessage name="name" class="error-feedback" />
      </div>

      <!-- Email -->
      <div class="form-group">
          <Field name="email" id="email" class="form-input" v-model="email"  type="text" placeholder="Email adres"/>
      </div>
      <div class="form-group">
        <ErrorMessage name="email" class="error-feedback" />
      </div>

      <!-- Phone -->
      <div class="form-group">
          <Field name="phone" id="phone" class="form-input" v-model="phone"  type="text" placeholder="Telefoon"/>
      </div>

      <!-- Address -->
      <div class="form-group">
         <!-- <label for="origin">Pickup / origin</label> -->
        <GMapAutocomplete 
        id="origin"
        name="origin"
        v-model="origin"
        class="form-input"
        placeholder="Start / begin adres"
        @place_changed="setAddress"
        >
        </GMapAutocomplete>
      </div>
      <div class="form-group">
        <ErrorMessage name="origin" class="error-feedback" />
      </div>



      <!-- Zip + city -->
      <div class="form-group">
        <div class="col-2 col-2-c">
          <Field name="zip" id="zip" class="form-input" v-model="zip"  type="text" placeholder="Postcode"/>

        </div>
        <div class="col-2 col-2-c">
 
          <Field name="city" id="city" class="form-input" v-model="city"  type="text" placeholder="Stad / plaats"/>
        </div>
      </div>








      <!-- Note -->
      <div class="form-group">
          <Field name="note" as="textarea" v-model="note" class="form-input" placeholder="Opmerkingen en/of notities"></Field>
      </div>



      
  </div>
        <!-- Controls -->
      <div class="form-group" style="left:10%;">
        <button class="default-button bottom-button" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Create</span>
        </button>
      </div>
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import {ref} from 'vue'

import ContactService from "../../services/contact-service";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";


export default {
  name: "trips-new",
  
  components: {
    Form,
    Field,
    ErrorMessage,
      'main-titlebar': require('@/components/titlebar.vue').default,
      'main-nav': require('@/components/mainmenu.vue').default,
  },
  data() {
    const name = ref('')
    const email = ref('')
    const phone = ref('')
    const address = ref('')
    const origin = ref('')
    const zip = ref('')
    const city = ref('')
    const note = ref('')
    const schema = yup.object().shape({
      // luggage: yup.string().required("Type is required"),
      // price: yup.string().required("Price is required"),
      name: yup.string().required("Naam is verplicht"),
      email: yup.string().required("Email is verplicht")
    });

   


    var myContacts = [];
    
    return {
      loading: false,
      message: "",
      schema,
      name,
      email,
      phone,
      address,
      zip,
      city,
      note,
      myContacts,
      origin
    };
  },
    mounted() {
    var targ = this;

     ContactService.getMyContacts().then(
      (response) => {
        console.log("ContactService.getMyContacts: data loaded from: " + JSON.stringify(response));
        targ.status = response.status;
        targ.myContacts = response.data;
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

  },
  computed: {

  },
  created() {
    if (this.loggedIn()) {
      console.log("New trip: user loged in: " + this.loggedIn());
    }
  },
  methods: {
    setAddress(targ) {
      this.setPlace(targ, "address")
    },
    setPlace(targ) {
      var address = targ.formatted_address
      var latlng = targ.geometry.location;
      var address_field = "address"; 
      this[''+address_field] = address;
      console.log("setPlace:" + address_field + " | " + address + " | " + latlng);

    },
    createContact(contact) {
      this.loading = true;
      console.log("createContact>> contact:" + JSON.stringify(contact));

      ContactService.createContact(contact).then(
        (response) => {
           console.log("createContact>> response:" + JSON.stringify(response));
           if(response.status == "failed") {
            alert("createContact>> Error: "+ response.message);
            // this.$router.go();

           } else {
             this.content = response.data;
            this.$router.push("/contacts");
           }
        },
        (error) => {
          console.log("createContact>> error:" + JSON.stringify(error));
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.$router.push("/contacts/new");
        }
      );

    },
  },
};


</script>